import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import {LibraryModifiedEvent} from "../_models/events/library-modified-event";
import {UserUpdateEvent} from "../_models/events/user-update-event";
import {NotificationProgressEvent} from "../_models/events/notification-progress-event";
import {SideNavUpdateEvent} from "../_models/events/sidenav-update-event";

export enum EVENTS {
  UpdateAvailable = 'UpdateAvailable',
  ScanMovie = 'ScanMovie',
  MovieAdded = 'MovieAdded',
  MovieRemoved = 'MovieRemoved',
  ScanLibraryProgress = 'ScanLibraryProgress',
  OnlineUsers = 'OnlineUsers',
  /**
   * A generic error that occurs during operations on the server
   */
  Error = 'Error',
  /**
   * A generic progress event
   */
  NotificationProgress = 'NotificationProgress',
  /**
   * A subtype of NotificationProgress that represents the underlying file being processed during a scan
   */
  FileScanProgress = 'FileScanProgress',
  /**
   * A subtype of NotificationProgress that represents a single series being processed (into the DB)
   */
  ScanProgress = 'ScanProgress',
  /**
   * A cover is updated
   */
  CoverUpdate = 'CoverUpdate',
  /**
   * A subtype of NotificationProgress that represents a file being processed for cover image extraction
   */
  CoverUpdateProgress = 'CoverUpdateProgress',
   /**
    * A library is created or removed from the instance
    */
  LibraryModified = 'LibraryModified',
   /**
    * A user updates an entities read progress
    */
  UserProgressUpdate = 'UserProgressUpdate',
   /**
    * A user updates account or preferences
    */
  UserUpdate = 'UserUpdate',
   /**
    * When the user needs to be informed, but it's not a big deal
    */
  Info = 'Info',
  /**
   * User's sidenav needs to be re-rendered
   */
  SideNavUpdate = 'SideNavUpdate',
}

export interface Message<T> {
  event: EVENTS;
  payload: T;
}


@Injectable({
  providedIn: 'root'
})
export class MessageHubService {
  hubUrl = environment.hubUrl;
  private hubConnection!: HubConnection;
  private hubConnectionSource = new BehaviorSubject(false);
  public hubConnection$ = this.hubConnectionSource.asObservable();

  private messagesSource = new ReplaySubject<Message<any>>(1);
  private onlineUsersSource = new BehaviorSubject<string[]>([]); // UserNames

  /**
   * Any events that come from the backend
   */
  public messages$ = this.messagesSource.asObservable();
  /**
   * Users that are online
   */
  public onlineUsers$ = this.onlineUsersSource.asObservable();

  constructor() {}

  /**
   * Tests that an event is of the type passed
   * @param event
   * @param eventType
   * @returns
   */
  public isEventType(event: Message<any>, eventType: EVENTS) {
    if (event.event == EVENTS.NotificationProgress) {
      const notification = event.payload as NotificationProgressEvent;
      return notification.eventType.toLowerCase() == eventType.toLowerCase();
    }
    return event.event === eventType;
  }

  createHubConnection(user: User) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl + 'messages', {
        accessTokenFactory: () => user.token
      })
      .withAutomaticReconnect()
      //.withStatefulReconnect() // Requires signalr@8.0
      .build();

    this.hubConnection
    .start()
    .catch(err => {
      console.error(err);
      this.hubConnectionSource.next(false);
    })
    .then(_ => {
      this.hubConnectionSource.next(true);
      this.hubConnection.on(EVENTS.OnlineUsers, (usernames: string[]) => {
        this.onlineUsersSource.next(usernames);
      });

      this.hubConnection.on(EVENTS.ScanMovie, resp => {
        this.messagesSource.next({
          event: EVENTS.ScanMovie,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.ScanLibraryProgress, resp => {
        this.messagesSource.next({
          event: EVENTS.ScanLibraryProgress,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.LibraryModified, resp => {
        this.messagesSource.next({
          event: EVENTS.LibraryModified,
          payload: resp.body as LibraryModifiedEvent
        });
      });

      this.hubConnection.on(EVENTS.SideNavUpdate, resp => {
        this.messagesSource.next({
          event: EVENTS.SideNavUpdate,
          payload: resp.body as SideNavUpdateEvent
        });
      });

      this.hubConnection.on(EVENTS.NotificationProgress, (resp: NotificationProgressEvent) => {
        this.messagesSource.next({
          event: EVENTS.NotificationProgress,
          payload: resp
        });
      });

      this.hubConnection.on(EVENTS.UserProgressUpdate, resp => {
        this.messagesSource.next({
          event: EVENTS.UserProgressUpdate,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.UserUpdate, resp => {
        this.messagesSource.next({
          event: EVENTS.UserUpdate,
          payload: resp.body as UserUpdateEvent
        });
      });

      this.hubConnection.on(EVENTS.Error, resp => {
        this.messagesSource.next({
          event: EVENTS.Error,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.Info, resp => {
        this.messagesSource.next({
          event: EVENTS.Info,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.MovieAdded, resp => {
        this.messagesSource.next({
          event: EVENTS.MovieAdded,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.MovieRemoved, resp => {
        this.messagesSource.next({
          event: EVENTS.MovieRemoved,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.CoverUpdate, resp => {
        this.messagesSource.next({
          event: EVENTS.CoverUpdate,
          payload: resp.body
        });
      });

      this.hubConnection.on(EVENTS.UpdateAvailable, resp => {
        this.messagesSource.next({
          event: EVENTS.UpdateAvailable,
          payload: resp.body
        });
      });
    });
  }

  stopHubConnection() {
    if (this.hubConnection) {
      this.hubConnectionSource.next(false);
      this.hubConnection.stop().catch(err => console.error(err));
    }
  }
}
