import { HttpClient } from '@angular/common/http';
import {DestroyRef, inject, Injectable } from '@angular/core';
import {catchError, of, ReplaySubject, throwError} from 'rxjs';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { ThemeService } from './theme.service';
import { TextResonse } from '../_types/text-response';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MessageHubService} from "./message-hub.service";

export enum Role {
  Admin = 'Admin',
  ChangePassword = 'Change Password',
  Bookmark = 'Bookmark',
  Download = 'Download',
  ChangeRestriction = 'Change Restriction',
  ReadOnly = 'Read Only',
  Login = 'Login',
  Promote = 'Promote',
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private readonly destroyRef = inject(DestroyRef);

  baseUrl = environment.apiUrl;
  userKey = 'lotus-user';
  public static lastLoginKey = 'lotus-lastlogin';
  public static localeKey = 'lotus-locale';
  private currentUser: User | undefined;

  // Stores values, when someone subscribes gives (1) of last values seen.
  private currentUserSource = new ReplaySubject<User | undefined>(1);
  public currentUser$ = this.currentUserSource.asObservable();

  private hasValidLicenseSource = new ReplaySubject<boolean>(1);
  /**
   * Does the user have an active license
   */
  public hasValidLicense$ = this.hasValidLicenseSource.asObservable();

  /**
   * SetTimeout handler for keeping track of refresh token call
   */
  private refreshTokenTimeout: ReturnType<typeof setTimeout> | undefined;

  private isOnline: boolean = true;

  constructor(private httpClient: HttpClient, private router: Router,
    private themeService: ThemeService, private messageHub: MessageHubService) {

    window.addEventListener("offline", (e) => {
      this.isOnline = false;
    });

    window.addEventListener("online", (e) => {
      this.isOnline = true;
      this.refreshToken().subscribe();
    });
  }

  hasAdminRole(user: User) {
    return user && user.roles.includes(Role.Admin);
  }


  hasDownloadRole(user: User) {
    return user && user.roles.includes(Role.Download);
  }


  getRoles() {
    return this.httpClient.get<string[]>(this.baseUrl + 'account/roles');
  }


  login(model: {username: string, password: string, apiKey?: string}) {
    return this.httpClient.post<User>(this.baseUrl + 'account/login', model).pipe(
      map((response: User) => {
        const user = response;
        if (user) {
          this.setCurrentUser(user);
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  isServerSetup() {
    return this.httpClient.get(this.baseUrl + 'server/is-setup', TextResonse).pipe(map(res => res == 'true'));
  }
  hasLibraryAccess(libraryId: number) {
    return this.httpClient.get(this.baseUrl + 'library/has-library-access?libraryId=' + libraryId, TextResonse).pipe(map(res => res == 'true'));
  }

  setCurrentUser(user?: User) {
    if (user) {
      user.roles = [];
      const roles = this.getDecodedToken(user.token).role;
      Array.isArray(roles) ? user.roles = roles : user.roles.push(roles);

      localStorage.setItem(this.userKey, JSON.stringify(user));
      localStorage.setItem(AccountService.lastLoginKey, user.username);

      // if (user.preferences && user.preferences.theme) {
      //   this.themeService.setTheme(user.preferences.theme.name);
      // } else {
      //   this.themeService.setTheme(this.themeService.defaultTheme);
      // }
      this.themeService.setTheme(this.themeService.defaultTheme);
    } else {
      this.themeService.setTheme(this.themeService.defaultTheme);
    }

    this.currentUser = user;
    this.currentUserSource.next(user);

    this.stopRefreshTokenTimer();

    if (this.currentUser) {
      this.messageHub.stopHubConnection();
      this.messageHub.hubConnection$.pipe(take(1)).subscribe(res => {
        this.messageHub.createHubConnection(this.currentUser!);
        this.startRefreshTokenTimer();
      });
    }
  }

  logout() {
    localStorage.removeItem(this.userKey);
    this.currentUserSource.next(undefined);
    this.currentUser = undefined;
    this.stopRefreshTokenTimer();
    this.messageHub.stopHubConnection();
    // Upon logout, perform redirection
    this.router.navigateByUrl('/login');
  }


  /**
   * Registers the first admin on the account. Only used for that. All other registrations must occur through invite
   * @param model
   * @returns
   */
  register(model: {username: string, password: string, email: string}) {
    return this.httpClient.post<User>(this.baseUrl + 'account/register', model).pipe(
      map((user: User) => {
        return user;
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  getDecodedToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }

  update(model: {email: string, roles: Array<string>, libraries: Array<number>, userId: number}) {
    return this.httpClient.post(this.baseUrl + 'account/update', model);
  }

  //
  // /**
  //  * This will get latest preferences for a user and cache them into user store
  //  * @returns
  //  */
  // getPreferences() {
  //   return this.httpClient.get<Preferences>(this.baseUrl + 'users/get-preferences').pipe(map(pref => {
  //     if (this.currentUser !== undefined && this.currentUser !== null) {
  //       this.currentUser.preferences = pref;
  //       this.setCurrentUser(this.currentUser);
  //     }
  //     return pref;
  //   }), takeUntilDestroyed(this.destroyRef));
  // }
  //
  // updatePreferences(userPreferences: Preferences) {
  //   return this.httpClient.post<Preferences>(this.baseUrl + 'users/update-preferences', userPreferences).pipe(map(settings => {
  //     if (this.currentUser !== undefined && this.currentUser !== null) {
  //       this.currentUser.preferences = settings;
  //       this.setCurrentUser(this.currentUser);
  //
  //       // Update the locale on disk (for logout and compact-number pipe)
  //       localStorage.setItem(AccountService.localeKey, this.currentUser.preferences.locale);
  //     }
  //     return settings;
  //   }), takeUntilDestroyed(this.destroyRef));
  // }

  getUserFromLocalStorage(): User | undefined {

    const userString = localStorage.getItem(this.userKey);

    if (userString) {
      return JSON.parse(userString)
    }

    return undefined;
  }

  refreshAccount() {
    if (this.currentUser === null || this.currentUser === undefined) return of();
    return this.httpClient.get<User>(this.baseUrl + 'account/refresh-account').pipe(map((user: User) => {
      if (user) {
        this.currentUser = {...user};
      }

      this.setCurrentUser(this.currentUser);
      return user;
    }));
  }


  private refreshToken() {
    if (this.currentUser === null || this.currentUser === undefined || !this.isOnline) return of();
    return this.httpClient.post<{token: string, refreshToken: string}>(this.baseUrl + 'account/refresh-token',
     {token: this.currentUser.token, refreshToken: this.currentUser.refreshToken}).pipe(map(user => {
      if (this.currentUser) {
        this.currentUser.token = user.token;
        this.currentUser.refreshToken = user.refreshToken;
      }

      this.setCurrentUser(this.currentUser);
      return user;
    }));
  }

  /**
   * Every 10 mins refresh the token
   */
  private startRefreshTokenTimer() {
    if (this.currentUser === null || this.currentUser === undefined) {
      this.stopRefreshTokenTimer();
      return;
    }

    this.stopRefreshTokenTimer();

    this.refreshTokenTimeout = setInterval(() => this.refreshToken().subscribe(() => {}), (60 * 10_000));
  }

  private stopRefreshTokenTimer() {
    if (this.refreshTokenTimeout !== undefined) {
      clearInterval(this.refreshTokenTimeout);
    }
  }

}
